import axios from "axios";
import { data } from "jquery";
//import { data } from "jquery";

//Base URL
const baseUrl = process.env.REACT_APP_FHIOS_API_URL

//******LOGIN

export async function loginCall(data) {
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "user/login"
  })
}

//******RESERVAS

//******BUSCADOR RESERVAS

function filterByEntrada(entradaDateValue) {
  return axios({
    method: "get",
    url: `${baseUrl}/bookingsearch_list?startDate=${entradaDateValue}`,
  });
}

export async function createObservation(token, id, creation_date, comment) {
  const data = {
    "user_token": token,
    "booking_id": id,
    "created_date": creation_date,
    "comment": comment,
    "user_token": localStorage.getItem("token")
  }
  return axios({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/create_observation",
  });
}

export async function deleteObservation(id) {
  const data = {
    "observation_id": id,
    "user_token": localStorage.getItem("token")
  }
  return axios({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/delete_observation",
  });
}

export async function editClient(data) {
  data.user_token = localStorage.getItem("token")
  console.log(data)
  return axios({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/edit-client",
  });
}

export async function editDataClient(data) {
  data.user_token = localStorage.getItem("token")
  console.log(data)
  return axios({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/edit_internal_ref",
  });
}

export async function getBookingSimple(date, centerId, isB2B, userToken) {
  return axios({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "simple_booking/between_dates?oneDayStartDate=" + date + "&centerId=" + centerId +
    `${isB2B !== undefined ? `&isB2b=${isB2B}` : ""}` +
    `${userToken !== undefined ? `&userToken=${userToken}` : ""}`
  });
}

export async function getBookingGrouped(date, centerId, isB2B, userToken) {
  return axios({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "group_booking/between_dates?oneDayStartDate=" + date + "&centerId=" + centerId +
    `${isB2B !== undefined ? `&isB2b=${isB2B}` : ""}` +
    `${userToken !== undefined ? `&userToken=${userToken}` : ""}`
  });
}

export async function getChannels(centerId, isB2B, userToken) {
  return axios({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "sales_channel_list?centerId=" + centerId +
    `${isB2B !== undefined ? `&isB2b=${isB2B}` : ""}` +
    `${userToken !== undefined ? `&userToken=${userToken}` : ""}`
  });
}

export async function getTarifasList(channelId, centerId, date) {
  return axios({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "tarifas_list_with_turn_available?salesChannelId=" + channelId + "&centerId=" + centerId + "&startDate=" + date
  });
}

export async function getTarifasListSimple(channelId, centerId, isB2B, userToken) {
  return axios({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "tarifas_list?salesChannelId=" + channelId + "&centerId=" + centerId +
    `${isB2B !== undefined ? `&isB2b=${isB2B}` : ""}` +
    `${userToken !== undefined ? `&userToken=${userToken}` : ""}`
  });
}

export async function getResourcesBooking(turnId, catActivityId, centerId, startDate, numPax, numRes, editMode, modeEdit) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "resources_for_booking?turnId=" + turnId + "&catActivityId=" + catActivityId + "&centerId=" + centerId + "&startDate=" + startDate + "&numPax=" + numPax + "&numRes=" + numRes + `${ editMode !== undefined ? `&bookActId=${editMode}` : "" }` + `${ modeEdit !== undefined ? `&mode=${modeEdit}` : "" }`
  })
}

export async function getServicesTypes(centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "aditional_services_type_list?centerId=" + centerId
  })
}

export async function getPaymentMethods(channelId, centerId, modeCreation=false) {
  return axios ({
    method: "get",
    //url: process.env.REACT_APP_FHIOS_API_URL + "pm_list?channelId=" + channelId  +"&centerId=" + centerId+"&creationMode=" + modeCreation
    url: process.env.REACT_APP_FHIOS_API_URL + `pm_list?${ channelId !== null ? `channelId=${channelId}&` : "" }` + "centerId=" + centerId+"&creationMode=" + modeCreation
  })
}

export async function createNewBooking(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/create"
  })
}

export async function getBookingInfo(bookingId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/obtain?bookingId=" + bookingId
  })
}

export async function deleteBooking(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/delete"
  })
}

export async function createPayment(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/create_payment"
  })
}

export async function createAditionalService(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/create_aditional_service"
  })
}

export async function deleteAditionalService(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/delete_aditional_service"
  })
}

export async function editAditionalService(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/edit_aditional_service"
  })
}

export async function deleteActivity(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/delete_booking_activity"
  })
}

export async function createActivity(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/create_booking_activity"
  })
}

export async function clientArrived(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/update_client_arrived"
  })
}

export async function clientChecking(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/update_client_checking"
  })
}

export async function bookingListTable(variables, isB2B, userToken) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + `bookingsearch_list?${variables.textSearch !== undefined ? `textSearch=${variables.textSearch}&` : ""}${variables.startDate !== undefined ? `startDate=${variables.startDate}` : ""}${variables.endDate !== undefined ? `&endDate=${variables.endDate}` : ""}${variables.createStartDate !== undefined ? `&createStartDate=${variables.createStartDate}` : ""}${variables.createEndDate !== undefined ? `&createEndDate=${variables.createEndDate}` : ""}${variables.centerId !== undefined ? `&centerId=${variables.centerId}` : ""}` +
    `${isB2B !== undefined ? `&isB2b=${isB2B}` : ""}` +
    `${userToken !== undefined ? `&userToken=${userToken}` : ""}`
  })
}

export async function isAdmin(token) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "user/is_admin?userToken=" + token
  })
}

export async function isCenterAdmin(token) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "user/is_center_admin?userToken=" + token
  })
}

export async function isFactAdmin(token) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "user/is_fact_admin?userToken=" + token
  })
}

export async function isFactPremium(token) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "user/is_fact_premium_admin?userToken=" + token
  })
}

export async function editPayment(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/edit_payment"
  })
}

export async function getAnotationsDay(date, centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/list_annotation?dateStart=" + date + "&centerId=" + centerId
  })
}

export async function getListWeather(centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/list_weathertype_by_center?centerId=" + centerId
  })
}

export async function addAnotationDay(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/create_annotation"
  })
}

export async function deleteAnotationDay(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/delete_annotation"
  })
}

export async function updateClientInvoice(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/update_client_invoice"
  })
}

export async function userInformation() {
  let data = {}
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "user/information"
  })
}

export async function activitiesFilter(centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "activity/list_activities_for_filter?centerId=" + centerId
  })
}

export async function getActivitiesBookingGroup(centerId, date, activity) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "activity/list_booking_group_for_activities?centerId=" + centerId + "&day=" + date + "&activity=" + activity
  })
}

export async function getBookingTableByActivity(centerId, date, activity) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "activity/list_booking_simple_for_activities?centerId=" + centerId + "&day=" + date + "&activity=" + activity
  })
}

export async function getResourcesTableActivity(centerId, date, activity) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "activity/list_resources_by_activity_and_day?centerId=" + centerId + "&day=" + date + "&activity=" + activity
  })
}

export async function reasignResource(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "activity/reassign_resources"
  })
}

export async function reasignResourcePax(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "activity/reassign_resources_by_pax"
  })
}

export async function reasignOverbookingResource(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "activity/reassign_resources_overbooking"
  })
}

export async function getListPromotions() {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/list_promotions"
  })
}

export async function getOverbookingTurnsAct(centerId, catId, startDate) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "turns_for_overbooking?centerId=" + centerId + "&catActivityId=" + catId + "&startDate=" + startDate
  })
}

export async function getTurnsForFamily(centerId, catId, startDate) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "turns_for_activity_family?centerId=" + centerId + "&catActivityId=" + catId + "&startDate=" + startDate
  })
}

export async function getOverbookingResources(catId, centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "resources_for_overbooking?catActivityId=" + catId + "&centerId=" + centerId
  })
}

export async function planningFamilyTable(centerId, day) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "activity/daily_planning_by_family?centerId=" + centerId + "&day=" + day
  })
}

export async function getTurnsByCenter(centerId, startDate) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "turns_by_center?centerId=" + centerId + "&startDate=" + startDate
  })
}

export async function editBookingActivity(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/edit_booking_activity"
  })
}

export async function yearlyByCenter(centerId, startDate, endDate) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/yearly_by_center?centerId=" + centerId + "&startDate=" + startDate + "&endDate=" + endDate
  })
}

export async function salesChannelByCenter(centerId, startDate, endDate) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/saleschannel_by_center?centerId=" + centerId + "&startDate=" + startDate + "&endDate=" + endDate
  })
}

export async function salesChannelTypeList() {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "sales_channel_type_list"
  })
}
export async function bookingPaymentByCenter(centerId, startDate, endDate, payment) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/booking_payment_by_center?centerId=" + centerId + "&startDate=" + startDate + "&endDate=" + endDate + "&paymentMethodId=" + payment
  })
}

export async function bookingActivitiesByCenter(centerId, startDate, endDate) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/booking_activities_by_center?centerId=" + centerId + "&startDate=" + startDate + "&endDate=" + endDate
  })
}

export async function isBillingAdmin(token) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "user/is_billing_admin?userToken=" + token
  })
}

export async function isSubBillingAdmin(token) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "user/is_sub_billing_admin?userToken=" + token
  })
}

export async function reloadTurns(centerId, catActivityId, startDate, numPax, numRes, isB2B) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "turn_list_filtered?centerId=" + centerId + "&catActivityId=" + catActivityId + "&startDate=" + startDate + "&numPax=" + numPax +
    `${numRes !== undefined ? `&numRes=${numRes}` : ""}` +
    `${isB2B !== undefined ? `&isB2b=${isB2B}` : ""}`
  })
}

export async function reloadTurnsSimple(centerId, catActivityId, startDate, isB2B) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "turn_list?centerId=" + centerId + "&catActivityId=" + catActivityId + "&startDate=" + startDate +
    `${isB2B !== undefined ? `&isB2b=${isB2B}` : ""}`
  })
}

export async function updateStatusBloqued(bookingId, status) {
  let data = {
    user_token: localStorage.getItem("token"),
    booking_id: bookingId,
    status: status
  }
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "booking/update_status_blocked"
  })
}

export async function toValidate(startDate, endDate, oneDayStartDate, centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "bookings/to_validate?" + `${startDate !== null ? `startDate=${startDate}&` : ""}` +
    `${endDate !== null ? `endDate=${endDate}&` : ""}` +
    `${oneDayStartDate !== null ? `oneDayStartDate=${oneDayStartDate}&` : ""}` +
    "centerId=" + centerId
  })
}

export async function pendingFacturation(startDate, endDate, oneDayStartDate, centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "bookings/pending_facturation_data?" + `${startDate !== null ? `startDate=${startDate}&` : ""}` +
    `${endDate !== null ? `endDate=${endDate}&` : ""}` +
    `${oneDayStartDate !== null ? `oneDayStartDate=${oneDayStartDate}&` : ""}` +
    "centerId=" + centerId
  })
}

export async function isB2B() {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "user/is_b2b?userToken=" + localStorage.getItem("token")
  })
}

export async function listDelivery(centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/list_delivery?centerId=" + centerId
  })
}

export async function recreateDelivery(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "delivery/recreate"
  })
}

export async function getDelivery(deliveryId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/get_delivery?deliveryId=" + deliveryId
  })
}

export async function listInvoices(centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/list_invoices?centerId=" + centerId
  })
}

export async function getInvoice(invoiceId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/get_invoice?invoiceId=" + invoiceId
  })
}

export async function getInvoiceRect(invoiceRectId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/get_invoice_rect?invoiceRectId=" + invoiceRectId
  })
}

export async function bookInvoices(centerId, companyFactId, startDate, endDate) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/list_invoices_by_company_fact?centerId=" + centerId +
    "&companyFactId=" + companyFactId +
    `${startDate !== null ? `&startDate=${startDate}` : ""}` +
    `${endDate !== null ? `&endDate=${endDate}` : ""}`
  })
}

export async function getSocialReasons(centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/company_fact_list?centerId=" + centerId
  })
}

export async function bookTreasury(centerId, pmId, startDate, endDate) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/list_invoices_treasury?centerId=" + centerId +
    "&pmId=" + pmId +
    `${startDate !== null ? `&startDate=${startDate}` : ""}` +
    `${endDate !== null ? `&endDate=${endDate}` : ""}`
  })
}

export async function deliveryCreate(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "delivery/create"
  })
}

export async function listBookings(centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/list_booking_filter?centerId=" + centerId
  })
}

export async function generateFact(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "invoice/create"
  })
}

export async function getClientFact(centerId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/list_client_fact?centerId=" + centerId
  })
}

export async function getFactByScCl(channelId, clientId) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "billing/list_invoice_by_sc_and_cl?clientId=" + clientId + "&salesChannelId=" + channelId
  })
}

export async function invoiceRect(data) {
  data.user_token = localStorage.getItem("token")
  return axios ({
    method: "post",
    data: data,
    url: process.env.REACT_APP_FHIOS_API_URL + "invoice_rect/create"
  })
}

export async function getPlanningByResources(centerId, startDate) {
  return axios ({
    method: "get",
    url: process.env.REACT_APP_FHIOS_API_URL + "activity/daily_planning_by_resources?centerId=" + centerId + "&day=" + startDate
  })
}

const apiManager = {
  loginCall,
  filterByEntrada,
  createObservation,
  deleteObservation,
  editClient,
  editDataClient,
  getBookingSimple,
  getBookingGrouped,
  getChannels,
  getTarifasList,
  getTarifasListSimple,
  getResourcesBooking,
  getServicesTypes,
  getPaymentMethods,
  createNewBooking,
  getBookingInfo,
  deleteBooking,
  createPayment,
  createAditionalService,
  deleteAditionalService,
  editAditionalService,
  deleteActivity,
  createActivity,
  clientArrived,
  clientChecking,
  bookingListTable,
  isAdmin,
  isCenterAdmin,
  isFactAdmin,
  isFactPremium,
  editPayment,
  getAnotationsDay,
  getListWeather,
  addAnotationDay,
  deleteAnotationDay,
  updateClientInvoice,
  userInformation,
  activitiesFilter,
  getActivitiesBookingGroup,
  getBookingTableByActivity,
  getResourcesTableActivity,
  reasignResource,
  reasignResourcePax,
  reasignOverbookingResource,
  getListPromotions,
  getOverbookingTurnsAct,
  getTurnsForFamily,
  getOverbookingResources,
  planningFamilyTable,
  getTurnsByCenter,
  editBookingActivity,
  yearlyByCenter,
  salesChannelByCenter,
  salesChannelTypeList,
  bookingPaymentByCenter,
  bookingActivitiesByCenter,
  isBillingAdmin,
  isSubBillingAdmin,
  reloadTurns,
  reloadTurnsSimple,
  updateStatusBloqued,
  toValidate,
  pendingFacturation,
  isB2B,
  listDelivery,
  recreateDelivery,
  getDelivery,
  listInvoices,
  getInvoice,
  getInvoiceRect,
  bookInvoices,
  getSocialReasons,
  bookTreasury,
  deliveryCreate,
  listBookings,
  generateFact,
  getClientFact,
  getFactByScCl,
  invoiceRect,
  getPlanningByResources
};
export default apiManager;
