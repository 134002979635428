import React, {useState, useEffect, useRef} from "react";
import { Table, Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import apiManager from "../../api/apiManager";
import { CSVLink } from "react-csv";

export default function TableInvoiceBook(props) {

  console.log(props)

  const csvLink = useRef()
  const [dataTable, setDataTable] = useState([])

  useEffect(() => {
    //console.log(props)
    if (props.socialReason !== "") {
      apiManager.bookInvoices(
        JSON.parse(localStorage.getItem("activeCenter")).id,
        props.socialReason,
        props.dates.length > 0 ? props.dates[0] : null,
        props.dates.length > 0 ? props.dates[1] : null
      )
      .then((res) => {
        let result = res.data.data
        console.log(result)
        props.dataOfTable(result)
        setDataTable(result)
      })
    }
  }, [])

  useEffect(() => {
    if (props.download > 0) {
      csvLink.current.link.click()
      //console.log(csvLink)
    }
  }, [props.download])

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
    },
    {
      title: 'Num. Factura',
      dataIndex: 'invoice_id',
      key: 'invoice_id',
    },
    {
      title: 'Cliente - Razón social',
      dataIndex: 'social_reason_client',
      key: 'social_reason_client',
    },
    {
      title: 'CIF',
      dataIndex: 'cif_client',
      key: 'cif_client',
    },
    {
      title: 'Dirección completa',
      dataIndex: 'address_client',
      key: 'address_client',
      render: (text) => <div dangerouslySetInnerHTML={{__html: text}} />
    },
    {
      title: 'Base Imponible',
      dataIndex: 'base_import',
      key: 'base_import',
      align: 'right',
      render: (text) => text.map((elem) => {
        return <><span>{ Number(elem).toFixed(2).replace(/[.,]00$/, "") + "€" }</span><br /></>
      })
    },
    {
      title: 'Tipo VAT',
      dataIndex: 'vat_type',
      key: 'vat_type',
      align: 'right',
      render: (text) => text.map((elem) => {
        return <><span>{ Number(elem).toFixed(2).replace(/[.,]00$/, "") + "%" }</span><br /></>
      })
    },
    {
      title: 'Importe VAT',
      dataIndex: 'vat_import',
      key: 'vat_import',
      align: 'right',
      render: (text) => text.map((elem) => {
        return <><span>{ Number(elem).toFixed(2).replace(/[.,]00$/, "") + "€" }</span><br /></>
      })
    },
    {
      title: 'TOTAL',
      dataIndex: 'total_import',
      key: 'total_import',
      align: 'right',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>
    },
  ]

  const headersCSV = [
    {
      label: 'Fecha',
      key: 'invoice_date',
    },
    {
      label: 'Num. Factura',
      key: 'invoice_id',
    },
    {
      label: 'Cliente - Razón social',
      key: 'social_reason_fact',
    },
    {
      label: 'CIF',
      key: 'client_info',
    },
    {
      label: 'Dirección completa',
      key: 'address_client',
    },
    {
      label: 'Base Imponible',
      key: 'base_import',
    },
    {
      label: 'Tipo VAT',
      key: 'vat_type',
    },
    {
      label: 'Importe VAT',
      key: 'vat_import',
    },
    {
      label: 'TOTAL',
      key: 'total_import',
    },
  ]

  return (
    <>
      <Table
        showHeader={props.socialReason !== ""}
        columns={columns}
        dataSource={dataTable} 
        bordered
        className="mainTable"
      />
      <CSVLink
        data={dataTable}
        headers={headersCSV}
        separator={","}
        style={{display: 'none'}}
        ref={csvLink}
        target='_blank'
        filename={`tb_${props.socialReasonText.replace(/\s/g, '-')}_${Date.now()}.csv`}
      >
        Descargar CSV
      </CSVLink>
    </>
  )
}